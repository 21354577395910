// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-preview-mdx": () => import("./../src/pages/preview.mdx" /* webpackChunkName: "component---src-pages-preview-mdx" */),
  "component---src-pages-styles-mdx": () => import("./../src/pages/styles.mdx" /* webpackChunkName: "component---src-pages-styles-mdx" */),
  "component---src-pages-temp-mdx": () => import("./../src/pages/temp.mdx" /* webpackChunkName: "component---src-pages-temp-mdx" */)
}


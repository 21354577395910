import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faLink } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
//https://github.com/FortAwesome/react-fontawesome#basic

const IconLink = props => {
    const getIcon = (name, style) => {
        switch (name) {
            case 'github':
                return <FontAwesomeIcon icon={faGithub} size="2x" css={imageStyle} />
            case 'linkedin':
                return <FontAwesomeIcon icon={faLinkedin} size="2x" css={imageStyle} />
            case 'website':
                return <FontAwesomeIcon icon={faLink} size="2x" css={imageStyle} />
            case 'code':
                return <FontAwesomeIcon icon={faCode} size="2x" css={imageStyle} />
            default: return <FontAwesomeIcon icon={faLink} size="2x" css={imageStyle} />
        }
    }

    let imageStyle = {
        color: '#fff',
        '&:hover': {
            color: '#3795f9'
        }
    };

    if (props.hasOwnProperty('imageStyle')) {
        imageStyle = {
            ...imageStyle,
            ...props.imageStyle
        }
    }

    const imageContainer = {
        margin: '6px'
    };

    return (
        <div css={imageContainer}>
            <a css={{}} href={props.link} target='_blank' rel="noopener noreferrer">
                {getIcon(props.name, imageStyle)}
            </a>
        </div>
    )
};
export default IconLink;
import React from 'react';
import IconLink from './IconLink';
import { SOCIAL_LINKS, AVAILABLE_SOCIALS } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';


const ProfileCard = (props) => {
    const { name, position } = props;

    const availableSocialLinks = Object.values(AVAILABLE_SOCIALS);

    const getSocialLink = (name) => {
        switch (name) {
            case 'github':
                return SOCIAL_LINKS.GITHUB;
            case 'linkedin':
                return SOCIAL_LINKS.LINKEDIN;
            default:
                return "";
        }
    };

    const SocialIcons = Object.keys(props)
        .filter(socialItem => (availableSocialLinks.includes(socialItem)))
        .map(socialLink => (
            <IconLink
                key={`${socialLink}-${props[socialLink]}`}
                name={socialLink}
                link={`${getSocialLink(socialLink)}${props[socialLink]}`}
            />
        ))

    console.log("Hello");
    

    return (
        <div css={styles.card}>
            <div css={styles.topContainer}>
                <div css={styles.imageContainer}>
                    {props.github
                    ? <img src={`${SOCIAL_LINKS.GITHUB_AVATAR}${props.github}`} css={styles.image}/>
                    : <FontAwesomeIcon icon={faUser} size="3x" />
                }
                </div>
                <div css={styles.name}>
                    <p css={styles.title}>{name}</p>
                    <p css={styles.text}>{position}</p>
                </div>
            </div>
            <div css={styles.bottomContainer}>
                {SocialIcons}
            </div>
        </div>
    )
};

const styles = {
    card: {
        display: 'flex',
        width: '250px',
        flexDirection: 'column',
        borderRadius: '10px',
        backgroundColor: '#212121',
        justifyContent: 'center',
        alignItems: 'center'
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#212121',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 3,
        marginTop: '30px',
        width: '90%',
        height: '80%',
        padding: '5%',
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#303030',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        width: '90%',
        height: '20%',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        padding: '5%'
    },
    imageContainer: {
        width: '100px',
        height: '100px',
        borderRadius: '200px',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '3px',
    },
    image: {
        'object-fit': 'contain',
        width: '100px',
        borderRadius: 'inherit'

    },
    name: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        marginTop: '5px',
        '& p': {
            padding: 0,
            margin: 0,
        }
    },
    title: {
        color: '#ffffff',
        fontSize: '2em',
        fontWeight: '400',
        textAlign: 'center',
        fontFamily: 'Mono'
    },
    text: {
        color: '#6f728b',
        textAlign: 'center',
        fontFamily: 'Mono'
    }
};

export default ProfileCard;